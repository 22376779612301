body, html {
  height: 100%;
}

body {
  background-color: #f2f2f2;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

ul, ol, li, figure {
  margin: 0;
  padding: 0;
}

ul, li {
  list-style: none;
}

p {
  line-height: 32px;
}

a, .btn, .clickable {
  opacity: 1;
  transition: all  0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}

a:hover, .btn:hover, .clickable:hover {
  opacity: 0.8;
}

img { width: 100%; }

.blue {
  color: $hpd-blue;
}

h3 {
  margin-top: 0;
}


.container {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  @include breakpoint($small) {
    max-width: 600px;
  }

  @include breakpoint($medium) {
    max-width: 960px;
  }

  @include breakpoint($large) {
    max-width: 1440px;
  }

  &:after {
    content: " ";
    display: block;
    clear: both;
  }
}

figure { text-align: center;}

section, footer {

    padding-top: 5.5em;
    padding-bottom: 5.5em;

    @include breakpoint($medium) {
      padding-top: 7.33333em;
      padding-bottom: 7.33333em;
    }
    @include breakpoint($large) {
      padding-top: 11em;
      padding-bottom: 11em;
    }

}


#welcome {

  h2 {
    @include full;
  }
  @include breakpoint($medium) {
    .text-block { @include span(8); }
    figure { @include span(last 4); }
  }
  @include breakpoint($large) {
    .text-block { @include span(6); }
    figure { @include span(last 6); }
  }

}

footer {
  clear: both;
  text-align: center;
  height: auto;
  ul {
    margin-top: 50px;
    li {
      display: inline-block;
      width: 140px;
      a {
        display: inline-block;
      }
      i {
        font-size: 100px;
        &::before {
          margin-top: 20px;
        }
        &.icon-twitter-bird::before {

          // font-size: 64px;
          // @include breakpoint($x-small) {
          //   font-size: 84px;
          // }
        }
      }
    }
  }
}
