/* Preloader */

#preloader {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:$hpd-blue; /* change if the mask should have another color then white */
  z-index:99; /* makes sure it stays on top */
}


#status {
  width:200px;
  height:200px;
  position:absolute;
  left:50%; /* centers the loading animation horizontally one the screen */
  top:50%; /* centers the loading animation vertically one the screen */
  margin:-100px 0 0 -100px; /* is width and height divided by two */
}

.vjs-control-bar {
    display: none;
}

// video {
//   max-width: 100%;
//   height: auto;
// }

#hero-video{
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  bottom: 0;
  // width: auto;
  // height: auto;
  background: $hpd-blue;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

}

.intro-wrapper {
    background: $hpd-blue;
}
.intro-section {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  text-align: center;
  background: $hpd-blue;
}


.intro-headline-wrapper {
  position: absolute;
  z-index: 20;
  text-align: left;
  right: 22px; top: 10%;

  h1 {
    line-height: 10px;
  }
  h1 span{
    font-family:'HelveticaNeueW01-86Heav';
    text-transform: uppercase;
    color: #fff;
    font-weight: 300;
    font-size: 23px;
    line-height: 19px;
    letter-spacing:-1px;

    &.f-w { margin-left: 8px; }
    &.s-w { margin-left: 4px; }
  }

    @include breakpoint($x-small) {
    right: 50px; top: 30%;

    h1 span{
      font-size: 43px;
      line-height: 33px;
      letter-spacing:-2px;

      &.f-w { margin-left: 17px; }
      &.s-w { margin-left: 9px; }
    }
  }

  @include breakpoint($medium) {
    right: 150px; top: 30%;

    h1 span{
      font-size: 46px;
      line-height: 36px;
      letter-spacing:-2px;

      &.f-w { margin-left: 17px; }
      &.s-w { margin-left: 9px; }
    }
  }

  @include breakpoint($large) {
    right: 150px; top: 30%;

    h1 span{
      font-size: 76px;
      line-height: 58px;
      letter-spacing:-4px;

      &.f-w { margin-left: 27px; }
      &.s-w { margin-left: 14px; }
    }
  }


}
