/* EXTERNAL DEPENDENCIES */

@import "vendor/normalize";


/* CONFIG AND OVERRIDES */
@import "config";

@import "utils/mixins";
@import "utils/placeholders";

@import "typography";

@import "vendor/susy";
@import "vendor/breakpoint";

@import "partials/settings";
@import "partials/typography";
@import "partials/base";
@import "partials/forms";
@import "partials/intro";
@import "partials/navigation";
@import "partials/decoration";
@import "partials/preface";
@import "partials/whatyoulearn";
@import "partials/sample";
@import "partials/authors";
@import "partials/buy-teaser";
@import "partials/live";
@import "partials/videos";

.buy-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
}

.buy-content-calltoaction {
  display: block;
  color: #FFF;
}

.buy-wrapper .buy-teaser .buy-content .buy-content-calltoaction {
  margin: 0;
  padding: 30px;
}

.buy-wrapper .buy-teaser .buy-content .amazon-cta {
  margin-top: 40px;
}

.buy-content-calltoaction-media {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}