#sample {
  i {
    display: inline-block;
    font-size: 150px;
    color: $hpd-blue;
    margin-bottom: 40px;
  }

  margin-top: 5.5em;


  text-align: center;

  h2 { @include span(12); }

  .p-first { @include span(12); }

  @include breakpoint($x-small) {
    margin-top: 5.5em;
  }

  @include breakpoint($small) {

  }

  @include breakpoint($medium) {
    form {
      @include span(6);
      @include push(3);
    }
  }

  @include breakpoint($large) {
  }

}
