

/* === TOP MENU LAYOUT === */
/* ======================= */

nav {
  color: #333;
  position: fixed;
  top: 0;
  left: 0;
  right:0;
  width: 100%;
  z-index: 98;
  cursor: pointer;
  background: transparent;
  text-align: center;
  padding: 20px 0;
  transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1), background 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transform: translate(0,0);
  display: none;

  @include breakpoint($medium) {
    display: block;
  }

  .navbar-right {
    float: right;
  }
  &.hidden {
    transform: translate(0,-58px) translate3d(0,0,0);
    background: #2f2f2f;
  }

  ul {
    display: block;
  }

  li {
    display: block;
    float: left;
  }
  a {
    display: block;
    color: #fff;
    font-family:'HelveticaNeueW01-86Heav';
    font-size: 20px;
    -webkit-transition: background 0.3s ease-in-out;
    -moz-transition: background 0.3s ease-in-out;
    transition: background 0.3s ease-in-out;
    padding: 10px 15px;
    line-height: 20px;

    &.nav-buy.btn {
      background-color: #fff;
      color: $hpd-blue;
      border-radius: 30px;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }



  &.compressed-nav {
    padding: 0;
    background: rgba(0,32,40,0.9);
    padding: 10px;
    a {

      &.nav-buy.btn {
        background-color: $hpd-blue;
        color: #fff;
      }
    }
  }

}

.section-status {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  z-index: 99;
  background-color: rgba(175,175,175,.45);

  .status-percent {
    display: block;
    height: 2px;
    width: 0;
    background: rgba(0,171,211,0.75);
    -webkit-transition: width;
    -moz-transition: width;
    -o-transition: width;
    transition: width;
  }
}

.animated {
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
  -o-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both
}

@-webkit-keyframes slideDown {
    0% {
        -webkit-transform: translateY(-4em)
    }

    100% {
        -webkit-transform: translateY(0)
    }
}

@-moz-keyframes slideDown {
    0% {
        -moz-transform: translateY(-4em)
    }

    100% {
        -moz-transform: translateY(0)
    }
}

@-o-keyframes slideDown {
    0% {
        -o-transform: translateY(-4em)
    }

    100% {
        -o-transform: translateY(0)
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(-4em)
    }

    100% {
        transform: translateY(0)
    }
}

.animated.slideDown {
    -webkit-animation-name: slideDown;
    -moz-animation-name: slideDown;
    -o-animation-name: slideDown;
    animation-name: slideDown
}

@-webkit-keyframes slideUp {
    0% {
        -webkit-transform: translateY(0)
    }

    100% {
        -webkit-transform: translateY(-4em)
    }
}

@-moz-keyframes slideUp {
    0% {
        -moz-transform: translateY(0)
    }

    100% {
        -moz-transform: translateY(-4em)
    }
}

@-o-keyframes slideUp {
    0% {
        -o-transform: translateY(0)
    }

    100% {
        -o-transform: translateY(-4em)
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(0)
    }

    100% {
        transform: translateY(-4em)
    }
}

.animated.slideUp {
    -webkit-animation-name: slideUp;
    -moz-animation-name: slideUp;
    -o-animation-name: slideUp;
    animation-name: slideUp
}