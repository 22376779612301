.buy-button {
  display: inline-block;
  font-family:'HelveticaNeueW01-86Heav';
  font-size: 20px;
  padding: 10px 35px;
  line-height: 40px;
  background-color: $hpd-blue;
  color: #fff;
  border-radius: 30px;
  margin: 0 auto;
  transition-duration: 0.2s;
  transition-property: transform;
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  &:hover {
    transform: rotate(-8deg);
  }
}


form {
  text-align: center;
  padding: 0;
}

.button {
  background-color: $hpd-blue;
  color: #fff;
  height: auto;
  font-family:'HelveticaNeueW01-86Heav';
  font-size: 20px;
  padding: 10px 35px;
  border-radius: 30px;
  line-height: 40px;
  opacity: 1;
  margin-top: 50px;
  cursor: pointer;
  transition: all  0.5s cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    opacity: 0.8;
  }
}


label {
  display: none;
}

input {
  display: block;
  width: 100%;
  padding: 8px 8px;
  text-indent: 2%;
  border: 0px solid #999;
  height: 50px;
  -webkit-appearance: none;
  font-family:'HelveticaNeueW01-86Heav';
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 5px;
  border-left: 0px solid $hpd-blue;
  transition: all  0.5s cubic-bezier(0.25, 0.1, 0.25, 1);

  &:focus {
    outline: none;
    border-left: 5px solid $hpd-blue;
  }
}


#sample-form-message {
  display: block;
  width: 100%;
  padding: 8px 8px;
  text-indent: 2%;
  font-family:'HelveticaNeueW01-86Heav';
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 5px;
  background: #ff9900;
  color: #fff;
  transition: all  0.5s cubic-bezier(0.25, 0.1, 0.25, 1);

}

.lname, .fname {
  display: none;
}

