.engines {
  position:absolute;
  top: 0;
  left: 0;
  z-index:1;
  transform: translate3d(0,0,0);
  // -webkit-backface-visibility: hidden;
  // -webkit-perspective: 1000;
}

hr {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 40 35\"><polygon fill=\"%23f2f2f2\" points=\"40,0 22.8,0 0,35 17.2,35 \"/></svg>");
  background-color: #01b7e5;
  height: 50px;
  width: 100%;
  margin: 0;
  border: 0;
  &.hrs {
    background-position: -90px;
  }
}

.paper-block {
  background: #fff;
  padding: 40px 40px;
  box-shadow: rgba(0, 0, 0, 0.4) 0 1px 3px;
}
