.preface {
  padding-bottom: 100px;
}

ol.videos {
  li {
    &:after {
      content: " ";
      display: block;
      clear: both;
    }
    &.paper-block {
      margin-bottom: 50px;
    }
  }
  div.video {
    margin-bottom:32px;
    @include breakpoint($medium) {
      @include span(6);
    }
    iframe {
      height: auto;
      min-height: 300px;
    }
  }
  div.detail {
    @include breakpoint($medium) {
      @include span(6 last);
      padding-left: 20px;
    }
    p {
      margin-top: 0;
    }
    ul {
      margin-left: 17px;
    }
    ul, li {
      line-height: 32px;
      padding-left: 0;
      list-style: disc;
    }
  }
}


