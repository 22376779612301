.preface-wrapper {
  background: url(../assets/img/mm_paper_b.jpg) repeat 10px 125px;
  background-size: 162px;

  .map-wrapper {
    background: url(../assets/img/map.svg) no-repeat -200px center;
    background-size: 3000px;
  }

  #preface {

    h2 {
      @include full;
      color: #fff;
    }

    img {
      display: none;
      transform:rotate(35deg);
      opacity: 0.6;
    }

    @include breakpoint($medium) {
      img { display: block; }
      h2 {
        padding-left: 40px;
        @include span(8);
        @include push(4);
      }
      figure { @include span(4); }
      .text-block { @include span(last 8); }
    }
    @include breakpoint($large) {
      h2 {
        padding-left: 40px;
        @include span(6);
        @include push(6);
      }
      figure { @include span(6); }
      .text-block { @include span( last 6); }
    }

  }
}