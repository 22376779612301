.buy-wrapper {
  background: $hpd-blue;
  @include full;
  .buy-teaser {
    padding-top: 9.16667em;
    padding-bottom: 9.16667em;

    .buy-content {
      margin:0 auto;
      color: #fff;
      .amazon-cta {
        color: #fff;
        text-align: center;
        opacity: 0.7;
        margin-top: 70px;
        p, span {
          font-family:'HelveticaNeueW01-86Heav';
          color: #fff;

        }
        .amazon-logo {
          background: url(../assets/img/amazon.svg) no-repeat bottom;
          width: 100px;
          height: 27px;
          display: inline-block;
          font-size: 0;
        }
        &:hover {
          opacity: 1;
        }
      }
      .buy-content-calltoaction {
        text-align: center;
        border: 8px solid #fff;
        color: #fff;
        padding: 1.5% 0%;
        margin: 0 auto 20px;
        max-width: 700px;
        opacity: 0.7;
        -webkit-transition: opacity 0.2s;
        -moz-transition: opacity 0.2s;
        -o-transition: opacity 0.2s;
        transition: opacity 0.2s;

        i {
          font-size: 60px;
        }

        &:hover {
          opacity: 1;
        }

        h3 {
          text-transform: uppercase;
          font-size: 30px;
        }
      }

      .disclaimer {
        font-family:'HelveticaNeueW01-86Heav';
        text-align: center;
        color: #fff;
        opacity: 0.7;
      }
    }

    .social-icons {
      text-align: center;
      li {
        display: inline;
        .fa {
          font-size: 40px;
        }
      }
    }
  }
}