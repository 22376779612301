.authors-wrapper {
  background: url(../assets/img/mm_paper.jpg) repeat 10px 125px;
  background-size: 162px;
  text-align: center;

  #authors {
    p {
      margin-left: 15px;
      margin-right: 15px;
    }
    .polaroid {
      width: 60%;
    }

    .author-twitter {
      font-size: 30px;
      display: inline-block;
      margin-bottom: 20px;
      span {
        font-weight: bold;
        font-size: 24px;
        margin-left: -7px;
      }
    }
    @include breakpoint($medium) {

      h2 {
        display: inline-block;
        height: 140px;
        width: 100%;
        &::before {
          background: url(../assets/img/arrow_l_d.svg) no-repeat right;
          content: "";
          display: inline-block;
          width: 100px;
          height: 80px;
          position: relative;
          bottom: -60px;
        }

        &::after {
          background: url(../assets/img/arrow_r_d.svg) no-repeat right;
          content: "";
          display: inline-block;
          width: 100px;
          height: 80px;
          position: relative;
          bottom: -60px;

        }
      }
    }

    @include breakpoint($x-small) {
      .column {padding: 0 20px;}
    }

    @include breakpoint($medium) {
      .column { @include span(6); padding: 0 80px;}
    }

    @include breakpoint($large) {
      .column { @include span(6); }
    }

  }
}

