@font-face{
  font-family:"HelveticaNeueW01-86Heav";
  src:url("../assets/fonts/2daec1fd-cd45-448e-b86e-ec73aacdb4ec.eot?#iefix");
  src:url("../assets/fonts/2daec1fd-cd45-448e-b86e-ec73aacdb4ec.eot?#iefix") format("eot"),url("../assets/fonts/eb32ec0b-c520-4a30-8777-e19326236901.woff") format("woff"),url("../assets/fonts/2d92bb71-9a10-499c-954f-8b61e8dd47f1.ttf") format("truetype"),url("../assets/fonts/e425d5b6-a4c5-4e1c-b29a-9a5b467b9645.svg#e425d5b6-a4c5-4e1c-b29a-9a5b467b9645") format("svg");
}

@font-face {
  font-family: 'hpd_icons';
  src: url('../assets/fonts/hpd_icons.eot?62453478');
  src: url('../assets/fonts/hpd_icons.eot?62453478#iefix') format('embedded-opentype'),
       url('../assets/fonts/hpd_icons.woff?62453478') format('woff'),
       url('../assets/fonts/hpd_icons.ttf?62453478') format('truetype'),
       url('../assets/fonts/hpd_icons.svg?62453478#hp') format('svg');
  font-weight: normal;
  font-style: normal;
}

$base-font-size: 18px;
$base-line-height: 33px;

body {
  font-family: Helvetica, Arial, sans-serif;
  color: #555;
  -webkit-font-smoothing: antialiased;
}

a, p, span, h1, h2, h3, h4, h5, .button {
  -webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}

h1,h2,h3,h4 {
  font-family:'HelveticaNeueW01-86Heav';
}

label {
  font-family:'HelveticaNeueW01-86Heav';
}

h2 {
    display: inline-block;
    position: relative;
    font-size: 28px;
    line-height: 35px;
    text-transform: uppercase;
    color: #333;
    margin-bottom: 30px;

    @include breakpoint($x-small) {
      font-size: 38px;
    }
}

h3 {
  font-size:30px;
}

a {
  text-decoration: none;
  color: $hpd-blue;
}

p {
  margin-bottom:30px;
  // font-family: Georgia,Cambria,"Times New Roman",Times,serif;
  font-family: Helvetica, Helvetica-Neue, sans-serif;

  &.p-first {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size:22px;
  }

  &.p-highlight {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size:22px;
  }
}

.p-list {
  margin-bottom:30px;
  li {
    list-style: square;
    color: $hpd-blue;
    font-weight: 600;
    margin-bottom: 13px;
  }
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "hpd_icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-ll_logo_sign_black:before { content: '\e800'; } /* '' */
.icon-book-1:before { content: '\e801'; } /* '' */
.icon-github:before { content: '\e802'; } /* '' */
.icon-down-bold:before { content: '\e803'; } /* '' */
.icon-right-bold:before { content: '\e805'; } /* '' */
.icon-paper-plane:before { content: '\e804'; } /* '' */
.icon-install:before { content: '\e807'; } /* '' */
.icon-twitter:before { content: '\e808'; } /* '' */