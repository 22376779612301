#learn {

  padding-bottom: 0em;
  text-align: center;

  h2 { @include span(12); }

  figure { @include span(12); }

  .p-first { @include span(12); }

  .text-block {
    @include span(12);
    padding-top: 3.66667em;
    padding-bottom: 0em;

    img {
      width: 80%;
      opacity: 0.3;
    }
  }

  @include breakpoint($x-small) {
    .span-block {
      @include full;
    }
  }

  @include breakpoint($small) {
    .span-block {
      @include gallery(6 of 12);
    }
  }

  @include breakpoint($medium) {
    .span-block {
      @include gallery(4 of 12);
    }
  }

  @include breakpoint($large) {
    .p-first {
      @include span(8);
      @include push(2);
    }

    .span-block {
      @include gallery(3 of 12);
    }

  }

  @include breakpoint($x-large) {
    .p-first {
      @include span(6);
      @include push(3);
    }

    .span-block {
      @include gallery(2 of 12);
    }

  }

}

.companies-teer-wrapper {
  background: url(../assets/img/teer.jpg);

  .companies-wrapper {
    background: url(../assets/img/riss.png) repeat-x top center;

    .companies-bottom-wrapper {
      background: url(../assets/img/riss_b.png) repeat-x bottom center;

      #companies {

        h2 {
          @include span(8);
          @include push(2);
          color: #fff;
        }

        text-align: center;

        img {
          width: 70%;
        }

        @include breakpoint($x-small) {

        }

        @include breakpoint($small) {

          img {
            width: 80%;
          }

          .co-logo {
            @include span (4 of 12);
          }
        }

        @include breakpoint($medium) {
          .co-logo {
            @include span (4 of 12);
          }
        }

        @include breakpoint($large) {
          .co-logo {
            @include span (4 of 12);
          }
        }
      }

    }
  }
}






#testimonials {

  h2 { @include span(12); }


  padding-bottom: 0em;
  text-align: center;

  blockquote {
    text-align: center;
    font-size: 18px;
    font-style: italic;
    margin: 0.25em 0;
    padding: 0.35em 40px;
    line-height: 1.45;
    position: relative;
    color: #383838;
  }
  blockquote:before {
      display: block;
      padding-left: 10px;
      content: "\201C";
      font-size: 80px;
      position: absolute;
      left: -20px;
      top: -20px;
      color: #7a7a7a;
  }
  blockquote cite {
      color: #999999;
      font-size: 14px;
      display: block;
      margin-top: 5px;
  }

  blockquote cite:before {
      content: "\2014 \2009";
  }

  .text-block {
    @include span(12);
    padding-top: 3.66667em;
    padding-bottom: 0em;
  }

  @include breakpoint($x-small) {
    .span-block {
      @include full;
      margin-bottom: 20px;
    }
  }

  @include breakpoint($small) {

  }

  @include breakpoint($medium) {
    .span-block {
      @include gallery(4 of 12);
      margin-bottom: 20px;
    }
  }

  @include breakpoint($large) {
    .p-first {
      @include span(8);
      @include push(2);
    }

    .span-block {
      @include gallery(4 of 12);
    }

  }

  @include breakpoint($x-large) {
    .p-first {
      @include span(6);
      @include push(3);
    }

    .span-block {
      @include gallery(4 of 12);
    }

  }


}
